<template>
  <div>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="formaspagamento"
        sort-by="descricao"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Formas de pagamento registradas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on"
                  >Nova forma de pagamento</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.descricao"
                          label="Descrição"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getCancelas">Reset</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// import { ModalCriarEditarPatio } from './ModalCriarEditarPatio'
import { mapActions, mapState } from 'vuex'
export default {
  // components: { ModalCriarEditarPatio },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
    }
  },
  computed: {
    ...mapState('patio', ['formaspagamento']),
    ...mapState('auth', ['empresaAtual']),
    formTitle() {
      return this.editedIndex === -1 ? 'Nova forma de pagamento' : 'Editar forma de pagamento'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.getFormasPagamento()
  },

  methods: {
    ...mapActions('patio', ['getFormasPagamento', 'addFormaPagamento', 'editFormaPagamento']),

    editItem(item) {
      this.editedIndex = this.formaspagamento.indexOf(item)
      Object.assign(this.editedItem, item)
      this.dialog = true
    },

    deleteItem(item) {
      console.log(item)
      // const index = this.desserts.indexOf(item)
      // confirm('Are you sure you want to delete this item?') &&
      //   this.desserts.splice(index, 1)
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        this.editFormaPagamento(this.editedItem)
      } else {
        this.editedItem.proprietario = this.empresaAtual.id
        this.addFormaPagamento(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
